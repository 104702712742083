import { FETCH_TRIVIA, FETCH_GAMES, FETCH_LEADERBOARD, FETCH_WINNINGS } from '../type';

const initialState = {};

const top6 = (one = [], two = []) => {
  return [...one, ...two].slice(0, 6).sort((a, b) => {
    return a?.available_date ? new Date(a?.available_date) - new Date(b?.available_date) : -1
  })
}

const triviaReducer = (trivia = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRIVIA:
      let data = ''
      if (payload) {
        data = []
        if (payload.length > 0) {
          data = payload.map(d => {
            d.bg_color = '#D2F0FF'
            d.dataType = 'trivia trivias'
            d.isAvailable = d?.available_date ? Date.now() > new Date(d?.available_date).getTime() : true
            return d
          })
        }
      }
      return { ...trivia, triviaList: data, topSix: top6(data, trivia?.games) };
    case FETCH_GAMES:
      let data2 = ''
      if (payload) {
        data2 = []
        if (payload.length > 0) {
          data2 = payload.filter(d => !d?.title?.toLowerCase()?.includes('tic tac')).map(d => {
            if (d?.title?.toLowerCase()?.includes('game-')) {
              d.title = d?.title?.split('game-')[1]?.replaceAll('-', ' ')
            }
            if (d?.title?.toLowerCase()?.includes('speed chop')) {
              d.insta = true
            }
            d.bg_color = '#FFF2D9'
            d.isGame = true
            d.dataType = 'game games'
            d.isAvailable = d?.available_date ? Date.now() > new Date(d?.available_date).getTime() : true
            return d
          })

        }
      }
      return { ...trivia, games: data2, topSix: top6(data2, trivia?.triviaList) };
    case FETCH_LEADERBOARD:
      return { ...trivia, leaderboard: payload };
    case FETCH_WINNINGS:
      return { ...trivia, winnings: payload };
    default:
      return trivia;
  }
};

export default triviaReducer;
